import * as styles from './SurveyTemplate.module.scss'

import React from 'react'
import { graphql, Link, PageProps } from 'gatsby'

import Layout from 'components/layout/Layout'
import PageMetaData from 'components/layout/PageMetaData'
import * as Typography from 'components/typography'

import Survey from 'components/survey/Survey'

interface Props extends PageProps<Queries.SurveyTemplateQuery> {
  pageContext: any
}

const SurveyTemplate: React.FC<Props> = (props) => {
  const { set, frameCard } = props.pageContext
  const { survey } = props.data

  if (survey == null) {
    return null
  }

  return (
    <Layout
      {...props.pageContext}
      frameCard={frameCard}
      accentColor={survey.accentColor}
      hideSurveyBanners
      includeNewsletterSignup={survey.status !== 'active'}
    >
      <div className={styles.container}>
        <div className={styles.heading}>
          <Typography.PrimaryHeading>
            {set.name} Cube&nbsp;Survey
          </Typography.PrimaryHeading>
        </div>

        {survey.status === 'pending' && (
          <Typography.Paragraph>
            <strong>Our {set.name} survey is opening soon!</strong> to be
            notified when it&rsquo;s open, sign up for our newsletter below:
          </Typography.Paragraph>
        )}

        {survey.status === 'active' && <Survey set={set} />}

        {survey.status === 'closed' && survey.articleSlug == null && (
          <Typography.Paragraph>
            <strong>
              Our {set.name} survey is closed and the results will be available
              soon.
            </strong>{' '}
            To be notified when our {set.name} prospective is published, and to
            make sure you don&rsquo;t miss our next survey you can sign up for
            our newsletter below.
          </Typography.Paragraph>
        )}

        {survey.status === 'closed' && survey.articleSlug != null && (
          <Typography.Paragraph>
            <strong>Our {set.name} survey is closed.</strong> You can read the
            results in our{' '}
            <Link to={`/articles/${survey.articleSlug}/`}>
              {set.name} Prospective
            </Link>
            . To make sure you don&rsquo;t miss our next survey you can sign up
            for our newsletter below.
          </Typography.Paragraph>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SurveyTemplate($setCode: String!) {
    survey: setSurveysYaml(code: { eq: $setCode }) {
      code
      status
      articleSlug
      accentColor
    }
  }
`

export const Head: React.FC<Props> = (props) => {
  const { set } = props.pageContext

  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: `${set.name} Cube Survey — Lucky Paper`,
        description: `Tell us what cards you're testing in your cube from ${set.name}. Results will be compiled, analyzed, and shared with the community.`,
      }}
    />
  )
}

export default SurveyTemplate
