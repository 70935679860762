import { useState, useMemo } from 'react'
import { uniq } from 'lodash'

import { CardRatings } from './useCardRatings'

export const usePasteCards = (ratings: CardRatings) => {
  const [content, setContent] = useState('')

  const lines = uniq(
    content
      .split('\n')
      .map((line) => line.trim())
      .filter((line) => line.length > 0),
  )

  const cardNames = useMemo(() => Object.keys(ratings.all), [ratings.all])

  const matchedCards = lines.reduce(
    (result, line) => {
      const cardName = cardNames.find(
        (name) => line.toLowerCase() === name.toLowerCase(),
      )

      if (cardName) {
        result.found.push(cardName)
      } else {
        result.missing.push(line)
      }

      return result
    },
    { found: [] as string[], missing: [] as string[] },
  )

  const addCards = () => {
    ratings.addList(matchedCards.found)
    setContent('')
  }

  return {
    content,
    setContent,
    cardsToAdd: matchedCards.found,
    missingCards: matchedCards.missing,
    addCards,
  }
}
