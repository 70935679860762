import * as styles from './Check.module.scss'

import React from 'react'

const Check: React.FC = () => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      className={styles.icon}
    >
      <polyline points="6.8,15.2 13.1,20.4 22.4,9.4" />
    </svg>
  )
}

export default Check
