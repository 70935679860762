import React from 'react'

const Remove: React.FC = () => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
    >
      <g style={{ fill: 'none', stroke: '#000000', strokeWidth: 2 }}>
        <polyline points="0.8,6.2 4.5,9.7 11.2,2.3 " />
      </g>
    </svg>
  )
}

export default Remove
