import * as styles from './Card.module.scss'

import React from 'react'
import classnames from 'classnames'

import { useIsTouchDevice } from 'utils/useIsTouchDevice'

import { CardRatings } from './utils/useCardRatings'
import { Card as CardType } from 'utils/surveySetData'

import { useCardHover } from 'components/cards/CardHover'
import CardImage from 'components/cards/CardImage'

import * as Icons from './icons'

interface Props {
  card: CardType
  ratings: CardRatings
  focused: boolean
  viewMode: 'text' | 'images'
}

const Card: React.FC<Props> = (props) => {
  const { card, ratings, viewMode, focused } = props

  const { hoverProps, portal } = useCardHover(card.name, card.set)

  const isTouchDevice = useIsTouchDevice(true)

  if (ratings.all[card.name] == null) {
    return null
  }

  const included = ratings.all[card.name].included

  return (
    <div
      className={classnames({
        [styles.textMode]: viewMode === 'text',
        [styles.imagesMode]: viewMode === 'images',
        [styles.included]: included,
        [styles.focused]: focused,
      })}
    >
      {viewMode === 'text' && (
        <>
          <div
            className={styles.label}
            {...(!isTouchDevice ? hoverProps : {})}
            onClick={() => ratings.toggle(card.name)}
          >
            <span>
              <span>{card.name}</span>

              {card.newRarity && (
                <span className={styles.rarityShifted}>(New Rarity)</span>
              )}

              {included ? (
                <span className={styles.smallCheck}>
                  <Icons.SmallCheck />
                </span>
              ) : null}
            </span>

            {focused && !included && (
              <div className={styles.keyboardHint}>ENTER &rarr;</div>
            )}
          </div>
          {
            <>
              {!focused && !included && isTouchDevice && (
                <div {...hoverProps} className={styles.viewPreview}>
                  View
                </div>
              )}
              {portal}
            </>
          }
        </>
      )}
      {viewMode === 'images' && (
        <div onClick={() => ratings.toggle(card.name)}>
          <CardImage
            set={card.set}
            // This is disabled for NCC. Eventually we'll need to fix this so
            // DFCs and other multipart cards render correctly.
            canFlip={false}
            flipMode="button"
            imageClass={classnames({
              [styles.imageIncluded]: included,
              [styles.imageFocused]: focused,
            })}
          >
            {card.name}
          </CardImage>

          {included && (
            <div className={styles.check}>
              <Icons.Check />
            </div>
          )}

          {card.newRarity && (
            <div className={styles.rarityShiftedBannerContainer}>
              <div className={styles.rarityShiftedBanner}>New Rarity</div>
            </div>
          )}
          {focused && !included && (
            <div className={styles.keyboardHint}>ENTER +</div>
          )}
        </div>
      )}
    </div>
  )
}

export default Card
