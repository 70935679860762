import * as styles from './RatingGuidelines.module.scss'

import React from 'react'

const RatingGuidelines: React.FC = () => {
  return (
    <div>
      <table className={styles.table}>
        <tr>
          <th align="center">Rank</th>
          <th align="left">Description</th>
        </tr>
        <tr>
          <td align="center">1</td>
          <td>
            Low grade. Cards you are testing, but don’t think will make the cut
            long-term.
          </td>
        </tr>
        <tr>
          <td align="center">5</td>
          <td>
            Medium grade. Cards you believe could have staying power in your
            cube, but aren’t slam dunks.
          </td>
        </tr>
        <tr>
          <td align="center">10</td>
          <td>
            High grade. Cards you think will be staples of your cube for years
            to come.
          </td>
        </tr>
      </table>
    </div>
  )
}

export default RatingGuidelines
