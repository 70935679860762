import { isEmpty } from 'lodash'

import { CardRatings } from './useCardRatings'

export const useValidation = (
  formData: { [key: string]: string },
  ratings: CardRatings,
) => {
  return (
    !isEmpty(formData.username) &&
    ratings.included.length > 0 &&
    ratings.included.reduce(
      (valid, rating) => valid && rating.rating != null,
      true,
    )
  )
}
