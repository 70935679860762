import * as styles from './Submitted.module.scss'

import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import { formatSentenceList } from 'utils/formatSentenceList'
import { Set } from 'utils/surveySetData'

import { TextButton } from 'components/controls'

import * as Typography from 'components/typography'
import NewsletterSignUpForm from 'components/newsletter/NewsletterSignUpForm'

import { CardRatings } from '../utils/useCardRatings'

import TestingList from './TestingList'

interface Props {
  set: Set
  onResetSurvey(): void
  ratings: CardRatings
}

const Submitted: React.FC<Props> = (props) => {
  const { set, onResetSurvey, ratings } = props

  const {
    all: { surveys },
  } = useStaticQuery<Queries.SurveySubmittedQuery>(graphql`
    query SurveySubmitted {
      all: allSetSurveysYaml(filter: { status: { eq: "active" } }) {
        surveys: nodes {
          code
          name
        }
      }
    }
  `)

  const otherSurveys = surveys.filter((survey) => survey.code !== set.code)

  const shareURL = `https://luckypaper.co/survey/${set.code}/`

  return (
    <>
      <Typography.SecondaryHeading>
        Thanks for sharing!
      </Typography.SecondaryHeading>
      <Typography.Paragraph>
        If you&rsquo;d like to be notified when the {set.name} prospective
        article is released, and make sure you never miss a future survey, sign
        up for our newsletter:
      </Typography.Paragraph>

      <NewsletterSignUpForm />

      {otherSurveys.length > 0 && (
        <Typography.Paragraph>
          {otherSurveys.length > 1 ? (
            <>Surveys are also open for </>
          ) : (
            <>A survey is also open for </>
          )}
          {formatSentenceList(
            otherSurveys.map((survey) => (
              <Link to={`/survey/${survey.code}/`} key={survey.code}>
                {survey.name}
              </Link>
            )),
          )}
          .
        </Typography.Paragraph>
      )}

      <Typography.SecondaryDivider />

      <div className={styles.moreOptions}>
        <Typography.Paragraph>
          <TextButton onClick={onResetSurvey}>
            Submit a survey for another cube
          </TextButton>
        </Typography.Paragraph>

        <TestingList set={set} ratings={ratings} />

        <Typography.Paragraph>
          {ratings.cubeCobraData && (
            <>
              <Link
                to={`/resources/cube-map/?cube=${ratings.cubeCobraData.cubeID}`}
              >
                View {ratings.cubeCobraData.cubeName || 'Your Cube'} on the Cube
                Map
              </Link>

              <br />
            </>
          )}
        </Typography.Paragraph>

        <Typography.Paragraph>
          <Link to="/set-prospectives/">View results from past sets</Link>
        </Typography.Paragraph>

        <Typography.Paragraph>
          <Link to="/set-prospectives/archive-search/">
            Search for your responses to past surveys
          </Link>
        </Typography.Paragraph>

        <Typography.Paragraph>
          <a
            href={`http://twitter.com/intent/tweet?url=${shareURL}&via=luckypapermtg`}
            title="Share on Twitter"
            target="_blank"
            rel="noreferrer"
          >
            Share the survey on Twitter
          </a>
        </Typography.Paragraph>
      </div>

      <Typography.Divider />
    </>
  )
}

export default Submitted
