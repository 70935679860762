/**
 * Flattens "other" fields. If a fields value is "other" and there is a matching
 * field like "fieldNameOther", that value will be used instead.
 *
 * Discards fields ending in 'other'.
 */
export function flattenOtherFields(data: { [key: string]: string }): {
  [key: string]: string
} {
  return Object.keys(data).reduce(
    (result, key) => {
      if (key.match(/\w+Other/)) {
        return result
      }

      if (data[key] === 'other' && data[`${key}Other`] != null) {
        result[key] = data[`${key}Other`]
      } else {
        result[key] = data[key]
      }

      return result
    },
    {} as {
      [key: string]: string
    },
  )
}
