import { useState, useEffect, useMemo } from 'react'

import { Card, Set } from 'utils/surveySetData'
import { useDebounce } from 'utils/useDebounce'
import { cubeIDFromLink, fetchCube } from 'utils/cube-cobra'
import { uniq } from 'lodash'

interface CubeCobraList {
  // The "long id" of the cube
  cubeID: string
  cubeName: string
  // Includes all cards in the cube mainboard and maybeboard list in the given
  // set.
  cardNames: {
    mainboard: string[]
    allBoards: string[]
  }
}

export const useCubeCobraList = (
  cubeLink: string | null,
  set: Set,
): CubeCobraList | null => {
  const cubeID = cubeIDFromLink(cubeLink)

  const [cubeData, setCubeData] = useState<{
    id: string
    name: string
    cardIDs: {
      mainboard: string[]
      maybeboard: string[]
    }
  } | null>(null)

  const debouncedID = useDebounce(cubeID, 500)

  useEffect(() => {
    if (!debouncedID) {
      return
    }

    fetchCube(debouncedID).then((result) => {
      setCubeData({
        id: result.id,
        name: result.name,
        cardIDs: {
          mainboard: result.cards.mainboard.map(
            (card) => card.cardID as string,
          ),
          maybeboard:
            result.cards.maybeboard?.map((card) => card.cardID as string) || [],
        },
      })
    })
  }, [debouncedID])

  const cardNamesInSet = useMemo(() => {
    if (!cubeData || !set.cards) {
      return null
    }

    const mainboard = filterCardNamesWithIDs(
      set.cards,
      cubeData.cardIDs.mainboard,
    )

    const maybeboard = filterCardNamesWithIDs(
      set.cards,
      cubeData.cardIDs.maybeboard,
    )

    return {
      mainboard: uniq(mainboard),
      allBoards: uniq([...mainboard, ...maybeboard]),
    }
  }, [cubeData, set])

  return useMemo(() => {
    if (!cubeID || cubeData == null || cardNamesInSet == null) {
      return null
    }

    return {
      cubeID: cubeData.id,
      cubeName: cubeData.name,
      cardNames: cardNamesInSet,
    }
  }, [cardNamesInSet, cubeData, cubeID])
}

function filterCardNamesWithIDs(cards: Card[], cardIDs: string[]) {
  return cards
    .filter((card) =>
      card.versionIDs.some((versionID) => cardIDs.includes(versionID)),
    )
    .map((card) => card.name)
}
