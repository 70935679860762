import { useState, useEffect } from 'react'

export const useWindowScrollPosition = () => {
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    const onScroll = (event: Event) => {
      if ((event.target as any)?.documentElement != null) {
        setScrollTop((event.target as any).documentElement.scrollTop)
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [setScrollTop])

  return { top: scrollTop }
}
