import * as styles from './Survey.module.scss'

import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react'
import { sortBy } from 'lodash'
import classNames from 'classnames'
import { Link, useStaticQuery, graphql } from 'gatsby'

import { usePersistentState } from 'utils/usePersistentState'
import { useWindowScrollPosition } from 'utils/useWindowScrollPosition'
import { useWindowSize } from 'utils/useWindowSize'
import { postFormData } from 'utils/postFormData'

import { TextButton } from 'components/controls'

import * as Typography from 'components/typography'
import * as Forms from 'components/forms'

import { copyToClipboard } from 'utils/copyToClipboard'
import { Set } from 'utils/surveySetData'
import { flattenOtherFields } from './utils/flattenOtherFields'

import { useCardRatings } from './utils/useCardRatings'
import { useFormData } from './utils/useFormData'
import { useCardFilters } from './utils/useCardFilters'
import { useValidation } from './utils/useValidation'
import { formatRatings } from './utils/formatRatings'

import Card from './Card'
import CardRating from './CardRating'
import EmptyResults from './EmptyResults'
import Filters from './Filters'
import PasteCardList from './PasteCardList'
import Questions from './Questions'
import RatingGuidelines from './RatingGuidelines'
import SurveyHeading from './Heading'
import SurveySubmitted from './submitted/Submitted'
import ViewOptions from './ViewOptions'

interface Props {
  set: Set
}

type ViewMode = 'text' | 'images'

const Survey: React.FC<Props> = (props) => {
  const { set } = props

  const sortedCards = useMemo(
    () =>
      sortBy(set.cards, [
        (card) => (card.set === set.code ? 'AAA' : card.set),
        'collectorNumberInt',
      ]),
    [set.cards, set.code],
  )

  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(false)

  const [submitted, setSubmitted] = usePersistentState(
    `${set.code}-survey-form-submitted`,
    false,
  )

  const formData = useFormData(set.code)

  const ratings = useCardRatings(set, sortedCards, formData.data.cubeLink)

  const filters = useCardFilters(sortedCards)
  const filteredCards = filters.cards

  const valid = useValidation(formData.data, ratings)

  const [viewMode, setViewMode] = usePersistentState<ViewMode>(
    'survey-view-mode',
    'text',
  )

  const [showPasteCardList, setShowPasteCardList] = useState(false)

  const [queryFocused, setQueryFocused] = useState(false)

  const [ratingGuideExpanded, setRatingGuideExpanded] = useState(false)

  const { site } = useStaticQuery<Queries.SetSurveyConfigQuery>(graphql`
    query SetSurveyConfig {
      site {
        siteMetadata {
          setSurveyUrl
        }
      }
    }
  `)

  const copy = useCallback(() => {
    const output = [
      new Date().toLocaleString(),
      formatRatings(ratings.included),
      set.code,
      ...Object.values(flattenOtherFields(formData.data)),
    ].join('\n\n')

    copyToClipboard(output)
  }, [formData.data, ratings.included, set.code])

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setSubmitting(true)

    const data = {
      timestamp: 'timestamp',
      cardRatings: formatRatings(ratings.included),
      set: set.code,
      viewMode,
      ...flattenOtherFields(formData.data),
    }

    if (site?.siteMetadata?.setSurveyUrl == null) {
      throw new Error('No set survey URL set')
    }

    postFormData(site.siteMetadata.setSurveyUrl, data).then((result) => {
      setError(false)
      setSubmitting(false)

      if (result?.data?.result === 'success') {
        setSubmitted(true)
      } else {
        setError(true)
      }
    })
  }

  const resetSurvey = () => {
    setError(false)
    setSubmitted(false)
    formData.reset()
    ratings.reset()
  }

  const ratingsElement = useRef<HTMLDivElement | null>(null)
  const scrollPosition = useWindowScrollPosition()
  const windowSize = useWindowSize()

  const scrolledToRatings =
    ratingsElement.current != null &&
    ratingsElement.current?.offsetTop < scrollPosition.top + windowSize.height

  const [selectedCardIndex, setSelectedCardIndex] = useState<number>(0)

  useEffect(() => {
    setSelectedCardIndex(0)
  }, [filteredCards])

  const canAddWithReturnKey =
    queryFocused && filters.query.length > 0 && filteredCards.length > 0

  const scrollToRatings = () => {
    if (ratingsElement.current != null) {
      ratingsElement.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  if (submitted) {
    return (
      <SurveySubmitted
        set={set}
        onResetSurvey={resetSurvey}
        ratings={ratings}
      />
    )
  }

  return (
    <form onSubmit={submit}>
      <Typography.Paragraph>
        Have a Cube? Excited for new {set.name} cards? Share what you&rsquo;re
        testing! This data will be compiled into{' '}
        <Link to="/set-prospectives/">an article</Link> to help cube designers
        evaluate and discover cards. All data will be published directly as a
        resource to the cube community.
      </Typography.Paragraph>

      <Questions set={set} formData={formData} ratings={ratings} />
      <Typography.SecondaryHeading>
        Cards You&rsquo;re Testing
      </Typography.SecondaryHeading>
      <ViewOptions
        viewMode={viewMode}
        setViewMode={setViewMode}
        ratingGuideExpanded={ratingGuideExpanded}
        setRatingGuideExpanded={setRatingGuideExpanded}
      />
      {ratingGuideExpanded && <RatingGuidelines />}
      <div>
        <div
          className={classNames(styles.cards, {
            [styles.cardsTextMode]: viewMode === 'text',
          })}
        >
          <div className={styles.cardColumn}>
            <div className={styles.cardColumnHeading}>
              <SurveyHeading>{set.name} Cards</SurveyHeading>
              <div className={styles.pasteButton}>
                <TextButton
                  onClick={() => setShowPasteCardList((value) => !value)}
                >
                  {showPasteCardList ? 'Cancel' : 'Paste List'}
                </TextButton>
              </div>
            </div>

            {showPasteCardList && (
              <PasteCardList
                ratings={ratings}
                close={() => setShowPasteCardList(false)}
              />
            )}

            {!showPasteCardList && (
              <>
                <Filters
                  filters={filters}
                  setQueryFocused={setQueryFocused}
                  onQueryReturn={() => {
                    if (canAddWithReturnKey) {
                      ratings.add(filteredCards[selectedCardIndex].name)
                      filters.setQuery('')
                    }
                  }}
                  cards={filteredCards}
                  setSelectedCardIndex={setSelectedCardIndex}
                />

                <div
                  className={classNames({
                    [styles.unratedCardsImagesMode]: viewMode === 'images',
                  })}
                >
                  {filteredCards.map((card, index) => (
                    <Card
                      key={card.name}
                      card={card}
                      ratings={ratings}
                      focused={
                        canAddWithReturnKey && index === selectedCardIndex
                      }
                      viewMode={viewMode}
                    />
                  ))}
                </div>

                {filteredCards.length === 0 && (
                  <EmptyResults filters={filters} />
                )}
              </>
            )}
          </div>

          <div
            className={styles.testingColumn}
            id="ratings"
            ref={ratingsElement}
          >
            <div className={styles.testingColumnSticky}>
              <SurveyHeading>
                Your Testing List
                {ratings.included.length > 0 ? (
                  <>
                    {' '}
                    - {ratings.included.length}{' '}
                    {ratings.included.length === 1 ? 'Card' : 'Cards'}
                  </>
                ) : null}
              </SurveyHeading>

              {ratings.included.length === 0 && (
                <div className={styles.emptyRatedCards}>
                  Select cards you plan to test in your cube.
                </div>
              )}

              <div
                className={classNames('ratedCards', {
                  [styles.ratedCardsImagesMode]: viewMode === 'images',
                })}
              >
                {ratings.included.map((rating) => (
                  <CardRating
                    key={rating.cardName}
                    rating={rating}
                    ratings={ratings}
                    viewMode={viewMode}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.submitContainer}>
          {viewMode === 'images' &&
            ratings.unratedCards > 0 &&
            !scrolledToRatings && (
              <button
                className={styles.jumpToRatings}
                type="button"
                onClick={scrollToRatings}
              >
                Rate {ratings.unratedCards}{' '}
                {ratings.unratedCards === 1 ? 'Card' : 'Cards'} &nbsp;↓
              </button>
            )}

          <div className={styles.submitControls}>
            <div className={styles.utilityButtons}>
              {ratings.included.length > 0 && (
                <>
                  <button
                    className={styles.utilityButton}
                    type="button"
                    onClick={ratings.reset}
                  >
                    Reset
                  </button>
                  <button
                    className={styles.utilityButton}
                    type="button"
                    onClick={copy}
                  >
                    Copy
                  </button>
                </>
              )}
            </div>

            <div className={styles.submitContainerValidityMessage}>
              {error && (
                <div className={styles.requirementsHint}>
                  <Forms.Hint>
                    <span className={styles.error}>
                      There was an error submitting your survey. Try again or
                      contact{' '}
                      <a href="mailto:mail@luckypaper.co">mail@luckypaper.co</a>
                      .
                      <button
                        className={styles.clearError}
                        onClick={() => setError(false)}
                      >
                        ✕
                      </button>
                    </span>
                  </Forms.Hint>
                </div>
              )}
              {!valid && (
                <div className={styles.requirementsHint}>
                  <Forms.Hint>
                    Please enter required fields, select at least one card, and
                    rate all selected cards.
                  </Forms.Hint>
                </div>
              )}
            </div>

            <button
              className={classNames(styles.submit, {
                [styles.submitting]: submitting,
              })}
              type="submit"
              disabled={!valid || submitting}
            >
              {!submitting && 'Submit'}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Survey
