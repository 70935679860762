import * as styles from './Questions.module.scss'

import React from 'react'

import * as Typography from 'components/typography'
import * as Forms from 'components/forms'

import { Set } from 'utils/surveySetData'

import { FormData } from './utils/useFormData'
import { CardRatings } from './utils/useCardRatings'

import ImportAvailableNotice from './ImportAvailableNotice'

interface Props {
  set: Set
  formData: FormData
  ratings: CardRatings
}

const Questions: React.FC<Props> = (props) => {
  const { set, formData, ratings } = props

  const onFormDataChanged = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.currentTarget

    formData.setValue(name, value)
  }

  return (
    <div className={styles.container}>
      <Typography.TextBlock>
        <div>
          <Forms.LargeLabel htmlFor="survey-username">
            Username
          </Forms.LargeLabel>

          <Forms.TextInput
            id="survey-username"
            type="text"
            name="username"
            value={formData.data.username || ''}
            onChange={onFormDataChanged}
            spellCheck={false}
          />

          <Forms.Hint>
            However you prefer to identify yourself to fellow cube designers.
          </Forms.Hint>
        </div>

        <div>
          <div>
            <Forms.LargeLabel>
              Cube Type <span className={styles.optional}>(Optional)</span>
            </Forms.LargeLabel>
          </div>

          <Forms.TextInput
            type="text"
            name="cubeType"
            value={formData.data.cubeType || ''}
            onChange={onFormDataChanged}
          />

          <Forms.Hint>
            Unrestricted, Pauper, Peasant, or however you describe your cube.
          </Forms.Hint>
        </div>

        <div>
          <Forms.LargeLabel htmlFor="survey-cubeLink">
            Link to Your Cube or Cube Cobra ID{' '}
            <span className={styles.optional}>(Optional)</span>
          </Forms.LargeLabel>

          <Forms.TextInput
            id="survey-cubeLink"
            type="text"
            name="cubeLink"
            value={formData.data.cubeLink || ''}
            onChange={onFormDataChanged}
            spellCheck={false}
          />

          <ImportAvailableNotice set={set} ratings={ratings} />
        </div>

        <div>
          <Forms.LargeLabel htmlFor="survey-additionalComments">
            Additional thoughts or comments on {set.name}?{' '}
            <span className={styles.optional}>(Optional)</span>
          </Forms.LargeLabel>

          <Forms.TextArea
            id="survey-additionalComments"
            name="additionalComments"
            rows={4}
            maxLength={3600}
            value={formData.data.additionalComments}
            onChange={onFormDataChanged}
          />
        </div>
      </Typography.TextBlock>
    </div>
  )
}

export default Questions
