export const formatRatings = (
  ratings: { cardName: string; rating: number | null }[],
) => {
  return ratings
    .map(
      (rating) =>
        `${rating.rating == null ? 'Unrated' : rating.rating}: ${
          rating.cardName
        }`,
    )
    .join('\n')
}
