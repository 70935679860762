import * as styles from './ViewOptions.module.scss'

import React from 'react'
import classnames from 'classnames'

import * as controls from 'components/controls'

type ViewMode = 'text' | 'images'

interface Props {
  viewMode: ViewMode
  setViewMode(viewMode: ViewMode): void
  ratingGuideExpanded: boolean
  setRatingGuideExpanded(expanded: boolean): void
}

const ViewOptions: React.FC<Props> = (props) => {
  const { viewMode, setViewMode, ratingGuideExpanded, setRatingGuideExpanded } =
    props

  return (
    <div className={styles.container}>
      <div>
        {!ratingGuideExpanded ? (
          <controls.TextButton onClick={() => setRatingGuideExpanded(true)}>
            Rating Guidelines
          </controls.TextButton>
        ) : (
          <controls.TextButton onClick={() => setRatingGuideExpanded(false)}>
            Hide Guidelines
          </controls.TextButton>
        )}
      </div>

      <div className={styles.mode}>
        <div>Display cards as:</div>
        <div
          className={classnames({
            [styles.deselected]: viewMode !== 'text',
          })}
        >
          <controls.SmallButton onClick={() => setViewMode('text')}>
            Text
          </controls.SmallButton>
        </div>
        <div
          className={classnames({
            [styles.deselected]: viewMode !== 'images',
          })}
        >
          <controls.SmallButton onClick={() => setViewMode('images')}>
            Images
          </controls.SmallButton>
        </div>
      </div>
    </div>
  )
}

export default ViewOptions
