import * as styles from './PasteCardList.module.scss'

import React from 'react'

import { CardRatings } from './utils/useCardRatings'
import { usePasteCards } from './utils/usePasteCards'

import { SmallButton } from 'components/controls'

import * as Forms from 'components/forms'

import ProseList from 'components/ProseList'

interface Props {
  ratings: CardRatings
  close(): void
}

const PasteCardList: React.FC<Props> = (props) => {
  const { ratings, close } = props

  const { content, setContent, cardsToAdd, missingCards, addCards } =
    usePasteCards(ratings)

  const missingCardsList =
    missingCards.length <= 6
      ? missingCards
      : [...missingCards.slice(0, 3), `${missingCards.length - 3} more`]

  // TODO: Implement this placeholder. Safari does not respect line breaks.
  // const placeholder = Object.keys(ratings.all).slice(0, 4).join('\n')

  return (
    <div className={styles.container}>
      <Forms.TextArea
        value={content}
        onChange={(event) => setContent(event.currentTarget.value)}
        rows={20}
        // placeholder={placeholder}
      />
      <div className={styles.hint}>
        One card per line, names must match exactly
      </div>

      {missingCards.length > 0 && (
        <div className={styles.warning}>
          {missingCards.length} {missingCards.length === 1 ? 'card' : 'cards'}{' '}
          not found: <ProseList>{missingCardsList}</ProseList>
        </div>
      )}

      <div className={styles.controls}>
        <SmallButton onClick={close}>Cancel</SmallButton>
        <SmallButton
          onClick={() => {
            addCards()
            close()
          }}
          disabled={cardsToAdd.length === 0}
        >
          {cardsToAdd.length === 0 ? (
            <>Add Cards</>
          ) : (
            <>
              Add {cardsToAdd.length}{' '}
              {cardsToAdd.length === 1 ? 'Card' : 'Cards'}
            </>
          )}
        </SmallButton>
      </div>
    </div>
  )
}

export default PasteCardList
