import * as styles from './CardRating.module.scss'

import React from 'react'
import classNames from 'classnames'

import * as Forms from 'components/forms'

import { CardRatings, Rating } from './utils/useCardRatings'

import { UnstyledCardHover } from 'components/cards/CardHover'
import CardImage from 'components/cards/CardImage'

import * as Icons from './icons'
import { toFloat } from 'src/utils/typeConversions'

interface Props {
  rating: Rating
  ratings: CardRatings
  viewMode: 'text' | 'images'
}

const CardRating: React.FC<Props> = (props) => {
  const { rating, ratings, viewMode } = props

  return (
    <div
      className={classNames({
        [styles.textMode]: viewMode === 'text',
        [styles.imagesMode]: viewMode === 'images',
      })}
    >
      {viewMode === 'images' && (
        <div className={styles.image}>
          <CardImage set={rating.set} flipMode="button">
            {rating.cardName}
          </CardImage>
        </div>
      )}

      <div className={styles.name}>
        {viewMode === 'text' && (
          <UnstyledCardHover card={rating.cardName} set={rating.set}>
            {rating.cardName}
          </UnstyledCardHover>
        )}

        <button
          className={styles.remove}
          title="Remove"
          type="button"
          onClick={() => ratings.remove(rating.cardName)}
        >
          <Icons.Remove />
        </button>
      </div>

      <div className={styles.rating}>
        <input
          className={styles.ratingValue}
          type="text"
          value={rating.rating ?? ''}
          onChange={(event) =>
            ratings.setRating(
              rating.cardName,
              toFloat(event.currentTarget.value),
            )
          }
          disabled
        />

        <div className={styles.ratingSelect}>
          {rating.rating == null && (
            <div className={styles.ratingPlaceholder}>
              {viewMode === 'text' ? 'Rate this card' : 'Rate'}
            </div>
          )}

          <Forms.RangeInput
            min={1}
            max={10}
            step={0.1}
            value={rating.rating}
            onClick={() => {
              if (rating.rating == null) {
                ratings.setRating(rating.cardName, 5)
              }
            }}
            onChange={(event) =>
              ratings.setRating(
                rating.cardName,
                parseFloat(event.currentTarget.value),
              )
            }
          />
        </div>
        <div className={styles.ratingDescription}>
          {rating.rating === 1 && (
            <>It&rsquo;s not gonna make it but I&rsquo;ve gotta try.</>
          )}
          {rating.rating && rating.rating > 1 && rating.rating < 4 && (
            <>Tentatively testing.</>
          )}
          {rating.rating && rating.rating >= 4 && rating.rating < 7 && (
            <>Interested, but not sure what to make of it.</>
          )}
          {rating.rating && rating.rating >= 7 && rating.rating < 10 && (
            <>Really like it — potentially a new staple.</>
          )}
          {rating.rating === 10 && <>I am never cutting this card.</>}
        </div>
      </div>
    </div>
  )
}

export default CardRating
