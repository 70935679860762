import { useMemo } from 'react'
import { isEmpty } from 'lodash'

import { usePersistentState } from 'utils/usePersistentState'
import { Card } from 'utils/surveySetData'

type Rarities = {
  common: boolean
  uncommon: boolean
  rare: boolean
  mythic: boolean
}

const defaultRarityFilters = {
  common: true,
  uncommon: true,
  rare: true,
  mythic: true,
}

type Colors = {
  W: boolean
  U: boolean
  B: boolean
  R: boolean
  G: boolean
  C: boolean
}

const defaultColorsFilter = {
  W: true,
  U: true,
  B: true,
  R: true,
  G: true,
  C: true,
}

export interface CardFilters {
  cards: Card[]
  query: string
  setQuery(query: string): void
  hideReprints: boolean
  setHideReprints(hide: boolean): void
  rarities: Rarities
  setRarities(rarities: Rarities): void
  colors: Colors
  setColors(colors: Colors): void
  nonDefaultFiltersSelected: boolean
  reset(): void
}

export const useCardFilters = (cards: Card[]): CardFilters => {
  const [query, setQuery] = usePersistentState('survey-filter-query', '')
  const [hideReprints, setHideReprints] = usePersistentState(
    'survey-filter-hideReprints',
    true,
  )
  const [rarities, setRarities] = usePersistentState(
    'survey-filter-rarities',
    defaultRarityFilters,
  )

  const [colors, setColors] = usePersistentState(
    'survey-filter-colors',
    defaultColorsFilter,
  )

  const nonDefaultFiltersSelected =
    !hideReprints ||
    Object.values(rarities).includes(false) ||
    Object.values(colors).includes(false)

  const reset = () => {
    setQuery('')
    setRarities(defaultRarityFilters)
    setColors(defaultColorsFilter)
    setHideReprints(false)
  }

  const filteredCards = useMemo(() => {
    const filters = [
      (card: Card) =>
        isEmpty(query) || card.name.toLowerCase().includes(query.toLowerCase()),
      (card: Card) => !hideReprints || card.newRarity || !card.reprint,
      (card: Card) => rarities[card.rarity],
      (card: Card) =>
        card.colors.some((c) => colors[c]) ||
        (card.colors.length === 0 && colors['C']),
    ]

    const filteredCards = cards.filter((card) => {
      return filters.reduce((accepted, filter) => {
        if (!accepted) {
          return false
        }
        return filter(card)
      }, true)
    })

    return filteredCards
  }, [cards, query, hideReprints, rarities, colors])

  return {
    cards: filteredCards,
    query,
    setQuery,
    hideReprints,
    setHideReprints,
    rarities,
    setRarities,
    colors,
    setColors,
    nonDefaultFiltersSelected,
    reset,
  }
}
