import * as styles from './EmptyResults.module.scss'

import React from 'react'

import { CardFilters } from './utils/useCardFilters'

interface Props {
  filters: CardFilters
}

const EmptyResults: React.FC<Props> = (props) => {
  const { filters } = props

  return (
    <div className={styles.container}>
      {filters.query.length > 0 ? (
        <>
          No cards matching &ldquo;{filters.query}&rdquo;.{' '}
          <button className={styles.clear} onClick={() => filters.setQuery('')}>
            Clear Search
          </button>
        </>
      ) : (
        <>
          No cards matching current filters.{' '}
          <button
            className={styles.clear}
            onClick={() => filters.reset()}
            type="button"
          >
            Reset Filters
          </button>
        </>
      )}
    </div>
  )
}

export default EmptyResults
