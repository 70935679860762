import * as styles from './ImportAvailableNotice.module.scss'

import React, { useMemo } from 'react'

import { AccentColor } from 'utils/accent-color/AccentColorContext'
import { cubeCobraGreen } from 'utils/cube-cobra/cubeCobraGreen'

import { SmallButton } from 'components/controls'

import { Set } from 'utils/surveySetData'

import { CardRatings } from './utils/useCardRatings'

import CubeCobraLogo from './icons/CubeCobraLogo'
import { difference } from 'lodash'

interface Props {
  set: Set
  ratings: CardRatings
}

const ImportAvailableNotice: React.FC<Props> = (props) => {
  const {
    set,
    ratings: { included, cubeCobraData, importCards },
  } = props

  const [allCards, mainImportable, allImportable] = useMemo(() => {
    if (cubeCobraData == null) {
      return [null, null]
    }

    const selectedCardNames = included.map((rating) => rating.cardName)

    return [
      cubeCobraData.cardNames.allBoards,
      difference(cubeCobraData.cardNames.mainboard, selectedCardNames),
      difference(cubeCobraData.cardNames.allBoards, selectedCardNames),
    ]
  }, [cubeCobraData, included])

  if (cubeCobraData == null) {
    return null
  }

  if (allCards == null || allImportable.length === 0) {
    return null
  }

  return (
    <AccentColor color={cubeCobraGreen}>
      <div className={styles.container}>
        <CubeCobraLogo />

        <div>
          <div className={styles.description}>
            {allCards.length} {allCards.length === 1 ? 'card' : 'cards'} from{' '}
            <em>{set.name}</em> found in your cube!
          </div>

          <div className={styles.add}>
            {mainImportable.length > 0 && (
              <SmallButton onClick={() => importCards(false)}>
                Add {mainImportable.length} Cards
              </SmallButton>
            )}

            {allImportable.length > mainImportable.length && (
              <SmallButton onClick={() => importCards(true)}>
                Add {allImportable.length} Including Maybeboard
              </SmallButton>
            )}
          </div>
        </div>
      </div>
    </AccentColor>
  )
}

export default ImportAvailableNotice
