import { useCallback, useMemo } from 'react'
import { sortBy, pick } from 'lodash'

import { usePersistentState } from 'utils/usePersistentState'
import { toObjectKeyedOn } from 'utils/collections'
import { Set, Card } from 'utils/surveySetData'

import { useCubeCobraList } from './useCubeCobraList'

export interface Rating {
  cardName: string
  set: string
  included: boolean
  rating: number | null
}

type RatingsByName = {
  [key: string]: Rating
}

export interface CardRatings {
  all: RatingsByName
  included: Rating[]
  add(name: string): void
  remove(name: string): void
  toggle(name: string): void
  setRating(name: string, rating: number | null): void
  unratedCards: number
  reset(): void
  cubeCobraData: ReturnType<typeof useCubeCobraList>
  importCards(includeMaybeboard: boolean): void
  addList(names: string[]): void
}

/**
 * Provides a list of card ratings for a given set of cards. A 'rating' is
 * created which includes both whether it's 'included' in rated cards and the
 * actual rating value. Cards may have a rating but not be included if they've
 * been added and removed.
 *
 * Note the sets of cards returned, and if they are in an array or hash is just
 * based on the specific use case for this hook, even if it's a bit awkward.
 *
 * Data from Cube Cobra is loaded based on the `cubeLink` if possible, including
 * a list of any cards in the set, in the cube cobra list, not currently in the
 * testing list, and the option to add those cards.
 */
export const useCardRatings = (
  set: Set,
  cards: Card[],
  cubeLink: string | null,
): CardRatings => {
  const setCode = set.code

  const defaultCardRatings: RatingsByName = useMemo(() => {
    return cards.reduce(
      (ratings, card) => ({
        [card.name]: {
          cardName: card.name,
          set: card.set,
          included: false,
          rating: null,
        },
        ...ratings,
      }),
      {},
    )
  }, [cards])

  const [cardRatings, setCardRatings] = usePersistentState<RatingsByName>(
    `${setCode}-survey-card-ratings`,
    defaultCardRatings,
    (savedRatings) => ({
      ...defaultCardRatings,
      // Filter out any potentially invalid ratings saved in local storage.
      ...pick(savedRatings, Object.keys(defaultCardRatings)),
    }),
  )

  const add = useCallback(
    (name: string) => {
      setCardRatings({
        ...cardRatings,
        [name]: {
          ...cardRatings[name],
          included: true,
        },
      })
    },
    [cardRatings, setCardRatings],
  )

  const remove = useCallback(
    (name: string) => {
      setCardRatings({
        ...cardRatings,
        [name]: {
          ...cardRatings[name],
          included: false,
        },
      })
    },
    [cardRatings, setCardRatings],
  )

  const toggle = useCallback(
    (name: string) => {
      setCardRatings({
        ...cardRatings,
        [name]: {
          ...cardRatings[name],
          included: !cardRatings[name].included,
        },
      })
    },
    [cardRatings, setCardRatings],
  )

  const setRating = useCallback(
    (name: string, rating: number) => {
      setCardRatings({
        ...cardRatings,
        [name]: {
          ...cardRatings[name],
          rating,
        },
      })
    },
    [cardRatings, setCardRatings],
  )

  const allCardRatings = useMemo(
    () => Object.values(cardRatings),
    [cardRatings],
  )

  const cardsByName = useMemo(() => toObjectKeyedOn(cards, 'name'), [cards])

  const included = useMemo(
    () =>
      sortBy(
        allCardRatings.filter((rating) => rating.included),
        [
          (rating) => {
            return cardsByName[rating.cardName]?.set === setCode
              ? 'AAA'
              : cardsByName[rating.cardName]?.set
          },
          (rating) => cardsByName[rating.cardName]?.collectorNumberInt,
        ],
      ),
    [setCode, allCardRatings, cardsByName],
  )

  const unratedCards = useMemo(() => {
    return included.filter((rating) => rating.rating == null).length
  }, [included])

  const reset = useCallback(() => {
    setCardRatings(defaultCardRatings)
  }, [defaultCardRatings, setCardRatings])

  const cubeCobraData = useCubeCobraList(cubeLink, set)

  const addList = useCallback(
    (names: string[]) => {
      const includedNames = included.map((rating) => rating.cardName)
      const newCards = names.filter((name) => !includedNames.includes(name))

      const newRatings = newCards.reduce((newRatings, card) => {
        return {
          ...newRatings,
          [card]: {
            ...cardRatings[card],
            included: true,
          },
        }
      }, {})

      setCardRatings({
        ...cardRatings,
        ...newRatings,
      })
    },
    [cardRatings, included, setCardRatings],
  )

  const importCards = useCallback(
    (includeMaybeboard: boolean) => {
      if (cubeCobraData != null) {
        addList(
          includeMaybeboard
            ? cubeCobraData.cardNames.allBoards
            : cubeCobraData.cardNames.mainboard,
        )
      }
    },
    [addList, cubeCobraData],
  )

  return {
    all: cardRatings,
    included,
    add,
    remove,
    toggle,
    setRating,
    unratedCards,
    reset,
    addList,
    cubeCobraData,
    importCards,
  }
}
