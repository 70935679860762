import React from 'react'

import { usePersistentState } from 'utils/usePersistentState'

export interface FormData {
  data: { [key: string]: string }
  setValue(key: string, value: string, general?: boolean): void
  reset(): void
}

// General fields are persisted separately so they will be auto filled for the
// next survey
const generalFields = ['username', 'cubeType', 'cubeLink']

export const useFormData = (identifier: string): FormData => {
  // Form data not specific to a set, such as username and cube link
  const [generalData, setGeneralData] = usePersistentState<{
    [key: string]: string
  }>(`general-survey-form-data`, {})

  const [setSpecificData, setSetSpecificData] = usePersistentState<{
    [key: string]: string
  }>(`${identifier}-survey-form-data`, {})

  const data = React.useMemo(
    () => ({ ...generalData, ...setSpecificData }),
    [generalData, setSpecificData],
  )

  const setValue = (key: string, value: string) => {
    if (generalFields.includes(key)) {
      setGeneralData({ ...generalData, [key]: value })
    } else {
      setSetSpecificData({ ...setSpecificData, [key]: value })
    }
  }

  const reset = () => {
    setSetSpecificData({})
  }

  return {
    data,
    setValue,
    reset,
  }
}
