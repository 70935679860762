import { useState, useEffect } from 'react'

/**
 * Debounce a value, returning an updated value only after not being invoked for
 * at least the given delay.
 *
 * Optionally, provide a function to conditionally update the value immedately.
 * This is useful if it's convenient in cases where empty values can more
 * conveniently be handled immedately.
 */
export function useDebounce<T>(
  value: T,
  delay = 100,
  debounce?: (value: T) => boolean,
): T {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    if (debounce != null && debounce(value) === false) {
      setDebouncedValue(value)
    } else {
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      return () => {
        clearTimeout(handler)
      }
    }
  }, [value, delay, debounce])

  return debouncedValue
}

export function useDebouncedState<T>(
  initial: T,
  delay = 100,
  debounce?: (value: T) => boolean,
): [T, (value: T) => void, T] {
  const [value, setValue] = useState(initial)

  const debouncedValue = useDebounce(value, delay, debounce)

  return [value, setValue, debouncedValue]
}
