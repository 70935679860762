// extracted by mini-css-extract-plugin
export var image = "CardRating-module--image--dba7b";
export var imagesMode = "CardRating-module--imagesMode--d2f6f";
export var name = "CardRating-module--name--6c16c";
export var rating = "CardRating-module--rating--b7df1";
export var ratingDescription = "CardRating-module--ratingDescription--7e706";
export var ratingPlaceholder = "CardRating-module--ratingPlaceholder--1cb0e";
export var ratingSelect = "CardRating-module--ratingSelect--b018b";
export var ratingValue = "CardRating-module--ratingValue--f03eb";
export var remove = "CardRating-module--remove--f52f4";
export var textMode = "CardRating-module--textMode--68aa5";