import * as styles from './Filters.module.scss'

import React, { useState } from 'react'
import { upperFirst } from 'lodash'
import classnames from 'classnames'

import { Card } from 'utils/surveySetData'

import Checkbox from 'components/forms/Checkbox'
import TextInput from 'components/forms/TextInput'

import { CardFilters } from './utils/useCardFilters'

import * as Icons from './icons'

interface Props {
  filters: CardFilters
  setQueryFocused(focused: boolean): void
  onQueryReturn(): void
  cards: Card[]
  setSelectedCardIndex: React.Dispatch<React.SetStateAction<number>>
}

const rarityNames = ['common', 'uncommon', 'rare', 'mythic']

const colorNames = {
  W: 'White',
  U: 'Blue',
  B: 'Black',
  R: 'Red',
  G: 'Green',
  C: 'Colorless',
}

const Filters: React.FC<Props> = (props) => {
  const {
    filters,
    setQueryFocused,
    onQueryReturn,
    cards,
    setSelectedCardIndex,
  } = props

  const onQueryKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()

      onQueryReturn()
    }
  }

  const onKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowUp') {
      setSelectedCardIndex((value) => Math.max(value - 1, 0))
    } else if (event.key === 'ArrowDown') {
      setSelectedCardIndex((value) => Math.min(value + 1, cards.length - 1))
    }
  }

  const [showAdvanced, setShowAdvanced] = useState(false)

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.query}>
          <TextInput
            placeholder="Filter Cards"
            value={filters.query}
            onChange={(event) => filters.setQuery(event.currentTarget.value)}
            onFocus={() => setQueryFocused(true)}
            onBlur={() => setQueryFocused(false)}
            onKeyDown={onQueryKeyDown}
            onKeyUp={onKeyUp}
          />
          <div className={styles.queryIcon}>
            <Icons.Search />
          </div>
        </div>

        <button
          className={classnames(styles.more, {
            'is-expanded': showAdvanced,
          })}
          type="button"
          onClick={() => setShowAdvanced(!showAdvanced)}
        >
          <div
            className={classnames(styles.moreIcon, {
              'is-expanded': showAdvanced,
            })}
          >
            <Icons.Expand color="#A8A8A8" />
          </div>
          {filters.nonDefaultFiltersSelected && (
            <div className={styles.filtersModified} />
          )}
        </button>
      </div>

      {showAdvanced && (
        <div className={styles.advanced}>
          <div className={styles.checkboxGroup}>
            <label className={styles.checkboxOption}>
              <Checkbox
                checked={filters.hideReprints}
                onChange={(event) =>
                  filters.setHideReprints(event.currentTarget.checked)
                }
              />{' '}
              Hide Reprints
            </label>
          </div>

          <div className={styles.checkboxGroup}>
            {rarityNames.map((rarityName) => (
              <label className={styles.checkboxOption} key={rarityName}>
                <Checkbox
                  checked={
                    (filters.rarities as Record<string, boolean>)[rarityName]
                  }
                  onChange={(event) =>
                    filters.setRarities({
                      ...filters.rarities,
                      [rarityName]: event.currentTarget.checked,
                    })
                  }
                />{' '}
                {upperFirst(rarityName)}
              </label>
            ))}
          </div>

          <div className={styles.checkboxGroup}>
            {Object.entries(colorNames).map(([key, label]) => (
              <label className={styles.checkboxOption} key={key}>
                <Checkbox
                  checked={(filters.colors as Record<string, boolean>)[key]}
                  onChange={(event) => {
                    filters.setColors({
                      ...filters.colors,
                      [key]: event.currentTarget.checked,
                    })
                  }}
                />{' '}
                {label}
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Filters
