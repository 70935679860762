import React from 'react'

interface Props {
  color?: string
}

const Expand: React.FC<Props> = (props) => {
  const { color } = props

  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
    >
      <g style={{ fill: 'none', stroke: color || '#000000', strokeWidth: 2 }}>
        <polyline points="1.7,5.6 7,9.8 12.3,5.6 " />
      </g>
    </svg>
  )
}

export default Expand
