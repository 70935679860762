import React from 'react'

export const formatSentenceList = (
  items: React.ReactNode[],
): React.ReactNode => {
  if (items.length === 1) {
    return items
  }

  if (items.length === 2) {
    return [items[0], <> and </>, items[1]]
  }

  return items.reduce(
    (sentence: React.ReactNode[], item, index) => [
      ...sentence,
      index === 0 ? null : index === items.length - 1 ? <>, and </> : <>, </>,
      item,
    ],
    [],
  )
}
