import React from 'react'

const Search: React.FC = () => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
    >
      <g style={{ fill: 'none', stroke: '#bbbdc2', strokeWidth: 2 }}>
        <circle cx="5.6" cy="5.6" r="4.7" />
        <line x1="8.9" y1="8.9" x2="13" y2="13" />
      </g>
    </svg>
  )
}

export default Search
