import React from 'react'

const Remove: React.FC = () => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
    >
      <g style={{ fill: 'none', stroke: '#000000', strokeWidth: 2 }}>
        <line x1="1.3" y1="1.3" x2="10.7" y2="10.7" />
        <line x1="10.7" y1="1.3" x2="1.3" y2="10.7" />
      </g>
    </svg>
  )
}

export default Remove
